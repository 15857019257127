import React from "react"
import styled from "styled-components"

const StyledList = styled.ul`
    list-style: disc;
    padding-left: 24px;
`
const StyledTable = styled.table`
    font-size: 14px;
`

const DatenschutzCopy = () => (
    <div>
        <h2>Datenschutzhinweise</h2>
        <br />
        <br />
        <h3>
            I. Hinweise im Zusammenhang mit der Agenturtätigkeit für die
            Manneimer Versicherung AG
        </h3>
        <br />
        <h4>1. Allgemeines</h4>
        <p>
            Hiermit informieren wir Sie über die Verarbeitung Ihrer
            personenbezogenen Daten durch uns als Vertretung der Mannheimer
            Versicherung AG und die Ihnen nach dem Datenschutzrecht zustehenden
            Rechte.
        </p>
        <br />
        <h4>
            2. Verantwortlicher für die Datenverarbeitung / Kontakt zum
            Datenschutzbeauftragten
        </h4>
        <p>
            Heiner Blaskewitz
            <br />
            Generalagent der Mannheimer Versicherung AG
            <br />
            Bahnhofstr. 35
            <br />
            29614 Soltau
        </p>
        <p>
            Telefon: +49(0)5191-9313199
            <br />
            Mobil +49(0)172-5113611
            <br />
            E-Mail: heiner.blaskewitz@mannheimer.com
            <br />
            Ein Datenschutzbeauftragter ist nicht bestellt, da dies gesetzlich
            nicht vorgeschrieben ist.
        </p>
        <br />
        <h4>3. Zweck und Rechtsgrundlagen der Datenverarbeitung</h4>
        <p>
            Eine Beratung sowie der Abschluss bzw. die Betreuung von
            Versicherungen ist ohne die Verarbeitung Ihrer personenbezogenen
            Daten nicht möglich.
        </p>
        <p>
            Wir verarbeiten Ihre personenbezogenen Daten unter Beachtung der
            EU-Datenschutz-Grundverordnung (DS-GVO), des
            Bundesdatenschutzgesetzes (BDSG), der datenschutzrechtlich
            relevanten Bestimmungen des Versicherungsvertragsgesetzes (VVG)
            sowie aller weiteren maßgeblichen Gesetze. Darüber hinaus hat sich
            unser Unternehmen auf die „Verhaltensregeln für den Umgang mit
            personenbezogenen Daten durch die deutsche Versicherungswirtschaft“
            verpflichtet, die die oben genannten Gesetze für die
            Versicherungswirtschaft präzisieren. Diese können Sie im Internet
            unter www.mannheimer.de/datenschutz abrufen.
        </p>
        <p>
            Die Ausübung unserer Tätigkeiten als Versicherungsvermittler ist
            nicht möglich ohne die Verarbeitung Ihrer personenbezogenen Daten
            einschließlich Daten der besonderen Art (z. B. Gesundheitsdaten).
            <br />
            Diese benötigen wir für:
        </p>
        <StyledList>
            <li>
                die Ermittlung Ihrer Bedürfnisse hinsichtlich
                Versicherungsprodukten,
            </li>
            <li>
                die Beratung im Rahmen der Vermittlung von
                Versicherungsprodukten,
            </li>
            <li>die Erfüllung unserer gesetzlichen Dokumentationspflichten,</li>
            <li>Angebotsberechnungen,</li>
            <li>
                Vorbereitungshandlungen und Abschlüsse von Versicherungsanträgen
                und -angeboten,
            </li>
            <li>
                Tätigkeiten während der Dauer des Versicherungsverhältnisses
                (Mitwirkung bei der Vertragsverwaltung und -erfüllung,
                insbesondere im Schaden-/Leistungsfall sowie bei einer
                Vertragsanpassung, -ergänzung oder für umfassende
                Auskunftserteilungen).
            </li>
        </StyledList>
        <p>
            Rechtsgrundlage für diese Verarbeitungen personenbezogener Daten für
            vorvertragliche und vertragliche Zwecke ist Art. 6
            Abs. 1 lit. b DS-GVO. Soweit dafür besondere Kategorien
            personenbezogener Daten (z. B. Ihre Gesundheitsdaten bei einer
            Risikovoranfrage für eine Kranken-, Lebens- oder Unfallversicherung)
            erforderlich sind, holen wir Ihre Einwilligung nach Art. 9
            Abs. 2 lit. a i. V. m. Art. 7 DS-GVO ein.
        </p>
        <p>
            Ihre Daten verarbeiten wir auch, um berechtigte Interessen von uns
            oder von Dritten zu wahren (Art. 6 Abs. 1 lit. f DS-GVO). Dies kann
            insbesondere erforderlich sein
        </p>
        <StyledList>
            <li>zur Gewährleistung der IT-Sicherheit und des IT-Betriebs,</li>
            <li>
                zur Geltendmachung rechtlicher Ansprüche und Verteidigung bei
                rechtlichen Streitigkeiten,
            </li>
            <li>zur Aktualisierung von Adressdaten,</li>
            <li>zur Verhinderung von Straftaten,</li>
            <li>
                bei Videoüberwachungen zur Wahrung des Hausrechts, zur Sammlung
                von Beweismitteln bei Überfällen und Betrugsdelikten,
            </li>
            <li>
                bei Maßnahmen, die der Gebäude- und Anlagensicherheit dienen
                (z. B. Zutrittskontrollen),
            </li>
            <li>bei Maßnahmen zur Sicherstellung des Hausrechts,</li>
            <li>
                zur Werbung für Produkte des Continentale Versicherungsverbunds
                und seiner Kooperationspartner sowie für Markt- und
                Meinungsumfragen.
            </li>
        </StyledList>
        <p />
        <p />
        <p />
        <p>
            Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
            erforderlich, stellen wir sicher, dass die Interessen, Grundrechte
            und Grundfreiheiten des Betroffenen nicht überwiegen. Die
            Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil
            sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er
            vertritt insoweit die Auffassung, dass ein berechtigtes Interesse
            anzunehmen sein kann, wenn die betroffene Person ein Kunde des
            Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).
        </p>
        <p>
            Darüber hinaus verarbeiten wir Ihre personenbezogenen Daten zur
            Erfüllung gesetzlicher Verpflichtungen (z. B. aufsichtsrechtlicher
            Vorgaben, handels- und steuerrechtlicher Aufbewahrungspflichten oder
            unserer Beratungspflicht). Als Rechtsgrundlage für die Verarbeitung
            dienen in diesem Fall die jeweiligen gesetzlichen Regelungen
            i. V. m. Art. 6 Abs. 1 lit. c DS-GVO.
        </p>
        <p>
            Sollten wir Ihre personenbezogenen Daten für einen oben nicht
            genannten Zweck verarbeiten wollen, werden wir Sie darüber zuvor
            informieren.
        </p>
        <br />
        <h4>
            4. Kategorien und Einzelne Stellen von Empfängern der
            personenbezogenen Daten
        </h4>
        <br />
        <h5>4.1 Externe Dienstleister</h5>
        <p>
            Sofern wir externe Dienstleister als Auftragsverarbeiter einsetzen,
            erfolgt dies im Rahmen einer Auftragsverarbeitungsvereinbarung.
        </p>
        <h5>4.2 Weitere Empfänger</h5>
        <p>
            Darüber hinaus können wir Ihre personenbezogenen Daten an weitere
            Empfänger übermitteln, wie etwa an Behörden zur Erfüllung
            gesetzlicher Mitteilungspflichten (z. B. Sozialversicherungsträger,
            Straßenverkehrsämter).
        </p>

        <br />
        <h4>5. Datenübermittlung in ein Drittland</h4>
        <p>
            Zur Prüfung und Erfüllung unserer vertraglichen Verpflichtung und
            unserer Serviceversprechen im Versicherungsfall kann es erforderlich
            sein, im Einzelfall Ihre personenbezogenen Daten an Dienstleister
            weiterzugeben. Bei einem Versicherungsfall außerhalb des
            Europäischen Wirtschaftsraums (EWR) kann es zu diesem Zweck
            erforderlich sein, dass wir oder unsere Dienstleister in Ihrem
            Interesse Ihre Daten an Dienstleister außerhalb des EWR geben
            müssen. Wir und unsere Dienstleister übermitteln Ihre Daten
            planmäßig nur, wenn diesem Drittland durch die EU-Kommission ein
            angemessenes Datenschutzniveau bestätigt wurde oder andere
            angemessene Datenschutzgarantien (z. B. verbindliche
            unternehmensinterne Datenschutzvorschriften oder
            EU-Standardvertragsklauseln) vorhanden sind, oder die Übermittlung
            auf einer Einwilligung von Ihnen beruht.
        </p>
        <br />
        <h4>6. Dauer der Speicherung Ihrer Daten</h4>
        <p>
            Wir löschen Ihre personenbezogenen Daten, sobald sie für die oben
            genannten Zwecke nicht mehr erforderlich sind. Dabei kann es
            vorkommen, dass personenbezogene Daten für die Zeit aufbewahrt
            werden, in der Ansprüche gegen unser Unternehmen geltend gemacht
            werden können (gesetzliche Verjährungsfrist von drei oder bis zu
            dreißig Jahren). Zudem speichern wir Ihre personenbezogenen Daten
            soweit wir dazu gesetzlich verpflichtet sind. Entsprechende
            Nachweis- und Aufbewahrungspflichten ergeben sich unter anderem aus
            dem Handelsgesetzbuch, der Abgabenordnung und dem Geldwäschegesetz.
            Die Speicherfristen betragen danach bis zu zehn Jahren.
        </p>
        <br />
        <h4>7. Betroffenenrechte</h4>
        <p>
            Sie können unter der o.g. Adresse Auskunft über die zu Ihrer Person
            gespeicherten Daten verlangen. Darüber hinaus können Sie unter
            bestimmten Voraussetzungen die Berichtigung oder die Löschung Ihrer
            Daten verlangen. Ihnen kann weiterhin ein Recht auf Einschränkung
            der Verarbeitung Ihrer Daten sowie ein Recht auf Herausgabe der von
            Ihnen bereitgestellten Daten in einem strukturierten, gängigen und
            maschinenlesbaren Format zustehen.
        </p>
        <p>
            Sie haben das Recht, einer Verarbeitung Ihrer personenbezogenen
            Daten zu Zwecken der Direktwerbung zu widersprechen. Verarbeiten wir
            Ihre Daten zur Wahrung berechtigter Interessen, können Sie dieser
            Verarbeitung widersprechen, wenn sich aus Ihrer besonderen Situation
            Gründe ergeben, die gegen die Datenverarbeitung sprechen.
        </p>
        <p>
            Sie haben die Möglichkeit, sich mit einer Beschwerde an den oben
            genannten Verantwortlichen für die Datenverarbeitung oder, sofern
            zutreffend, an unseren Datenschutzbeauftragten oder an die für uns
            zuständige Datenschutzaufsichtsbehörde zu wenden:.
        </p>
        <p>
            Die Landesbeauftragte für den Datenschutz Niedersachsen
            <br />
            Prinzenstr. 5 <br />
            30159 Hannover
            <br /> +49(0)511 / 120-4500
            <br /> +49(0)511 / 120-4599
            <br />
            E-Mail: poststelle@lfd.niedersachsen.de
        </p>
        <br />
        <h4>8. Aktualisierung der Datenschutzhinweise</h4>
        <p>
            Diese Datenschutzhinweise können aufgrund von Änderungen, z. B. der
            gesetzlichen Bestimmungen, zu einem späteren Zeitpunkt angepasst
            Hinweise sowie der Dienstleister, zu denen nicht nur vorübergehende
            Geschäftsbeziehungen bestehen, erhalten Sie beim unter Ziffer 2
            genannten Verantwortlichen für die Datenverarbeitung.
        </p>
        <br />
        <h3>
            II. Weitere Hinweise im Zusammenhang mit den Webseiteninhalten und
            Funktionen
        </h3>
        <br />
        <h4>1. Cookies</h4>
        {/* <p>
            Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
            Cookies sind kleine Textdateien, die von einem Websiteserver auf
            Ihre Festplatte übertragen werden. Hierdurch erhalten wir
            automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter
            Browser, Betriebssystem und Ihre Verbindung zum Internet.
        </p>
        <p>
            Cookies können nicht verwendet werden, um Programme zu starten oder
            Viren auf einen Computer zu übertragen. Anhand der in Cookies
            enthaltenen Informationen können wir Ihnen die Navigation
            erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
        </p>
        <p>
            In keinem Fall werden die von uns erfassten Daten an Dritte
            weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit
            personenbezogenen Daten hergestellt.
        </p>
        <p>
            Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
            betrachten. Internet-Browser sind regelmäßig so eingestellt, dass
            sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung
            von Cookies jederzeit über die Einstellungen Ihres Browsers
            deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres
            Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern
            können. Bitte beachten Sie, dass einzelne Funktionen unserer Website
            möglicherweise nicht funktionieren, wenn Sie die Verwendung von
            Cookies deaktiviert haben.
        </p> */}
        <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/3ac34810-389f-465b-8fea-c2fa20e36829/cd.js"
            type="text/javascript"
            async
        ></script>

        <br />
        <h4>2. SSL-Verschlüsselung</h4>
        <p>
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
            verwenden wir dem aktuellen Stand der Technik entsprechende
            Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </p>
        <br />
        <h4>3. Bewertungsfunktion</h4>
        <p>
            Wenn Nutzer Bewertungen auf dieser Website hinterlassen, werden
            neben diesen Angaben auch der Zeitpunkt ihrer Erstellung und der
            zuvor durch den Websitebesucher frei gewählte Anzeigename
            gespeichert.
        </p>
        <br />
        <h4>4. Anfrageformular </h4>
        <p>
            Treten Sie per Formular mit uns in Kontakt, erteilen Sie uns zum
            Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür
            ist die Angabe einer validen E-Mail-Adresse erforderlich. Diese
            dient der Zuordnung der Anfrage und der anschließenden Beantwortung
            derselben. Die von Ihnen gemachten Angaben werden zum Zwecke der
            Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
            gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden
            personenbezogene Daten automatisch gelöscht.
        </p>
        <br />
        <h3>Eingesetzte Dienste und Diensteanbieter:</h3>
        <br />
        <br />
        <h4>Make</h4>
        <p>
            Zur Übermittlung und automatisierten Beantwortung Ihrer Anfragen
            nutzen wir Make.com, einen Dienst von Celonis, Inc., One World Trade
            Center, 87th Floor, New York, NY, 10007, USA. Dabei werden
            Kundendaten ausschließlich verschlüsselt an die Server übermittelt
            und unmittelbar nach Übertragung an den Betreiber der Website
            musikversicherung.com gelöscht. Make.com hat keinen Zugriff auf die
            erhobenen Daten.
        </p>
        <p>
            ____________________________________________________________________________________________________________
        </p>

        <br />
        <p>
            Hinweis:
            <br />
            Die Datenschutzhinweise der Mannheimer Versicherung AG finden Sie
            hier:{" "}
            <a
                href="https://www.mannheimer.de/datenschutz-kunden"
                target="_blank"
                rel="noopener noreferrer"
                class="underline"
            >
                https://www.mannheimer.de/datenschutz-kunden
            </a>
        </p>
        <small>Stand 08.2022</small>
        <br />
        <br />
    </div>
)
export default DatenschutzCopy
